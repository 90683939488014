// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDigFy5S6wzuxFQwKlqFgRcemjmxXlcV08",
    authDomain: "luckydeliveriesonline.firebaseapp.com",
    databaseURL: "https://luckydeliveriesonline.firebaseio.com",
    projectId: "luckydeliveriesonline",
    storageBucket: "luckydeliveriesonline.appspot.com",
    messagingSenderId: "267407790081",
    appId: "1:267407790081:web:351e53051f2669c516b000",
    measurementId: "G-LC23L4FVX7"
  },
  api: 'https://www.luckydeliveries.com/admin_web_api',
  nearByConfig: {
    "radius": 20, //in kilometers,
    "timer": 10000 //in millisecond
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
