import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { environment } from '../../environments/environment';
declare var require: any;
const geofire = require('geofire');

@Component({
  selector: 'app-order-assign',
  templateUrl: './order-assign.component.html',
  styleUrls: ['./order-assign.component.css']
})
export class OrderAssignComponent implements OnInit {
  public order_id: any;
  orderSubscribe: any;
  fbOrderDetails: any;
  nearestDriversAround: any = [];
  radius: any = environment.nearByConfig.radius;
  timer: any = environment.nearByConfig.timer;
  driverSearchTimer: any;
  driverLocationGeo: any;
  public isVisible: boolean = false;
  displayMessage: any = 'Please wait...';
  driverIndex: any = 0;
  prevDriverIDStore: any;
  resSubscribe: any;
  requestAcceptedStatus: boolean = false;
  driverDetails: any;


  constructor(public activatedRoute: ActivatedRoute, private http: HttpClient, public afDb: AngularFireDatabase) {

  }

  showAlert(): void {
    if (this.isVisible) {
      return;
    }
    this.isVisible = true;
    setTimeout(() => this.isVisible = false, 2500)
  }

  ngOnInit() {
    this.isVisible = true;
    this.driverLocationDBinit();
    this.activatedRoute.queryParams.subscribe((res) => {
      console.log(res);
      // console.log(JSON.parse(res.order_id));
      if (res && res.order_id) {
        this.order_id = res.order_id;
        this.getOrderSubscribe(this.order_id);
      }
    });
  }

  ngOnDestroy() {
    this.orderSubscribe.unsubscribe()
  }

  driverLocationDBinit() {
    var driverLocationDBRef = this.afDb.database.ref('driversLocation');
    this.driverLocationGeo = new geofire.GeoFire(driverLocationDBRef);
    console.log(this.driverLocationGeo);
  }

  getOrderSubscribe(order_id) {
    console.log(order_id);
    let self = this;

    self.orderSubscribe = self.afDb.object('/orders/' + self.order_id).subscribe(res => {
      console.log(res);
      this.fbOrderDetails = res;

      if (self.fbOrderDetails && self.fbOrderDetails.uba_restulat && self.fbOrderDetails.uba_restulong && self.fbOrderDetails.order_status == 1) {
        // self.orderSubscribe.unsubscribe();
        setTimeout(() => {
          self.getDriversNearestRestaurant(parseFloat(self.fbOrderDetails.uba_restulat), parseFloat(self.fbOrderDetails.uba_restulong), self.radius);
        }, 1000);
      } else {
        self.requestAcceptedStatus = true;
        if (this.fbOrderDetails.uba_driver) {
          self.getFbDriver(this.fbOrderDetails.uba_driver);
        }
      }
    });

    this.nearestDriversAround = [];


    if (self.driverSearchTimer) {
      clearInterval(self.driverSearchTimer);
    }

    // setTimeout(() => {
    //   self.getDriversNearestRestaurant(parseFloat(self.fbOrderDetails.uba_restulat), parseFloat(self.fbOrderDetails.uba_restulong), self.radius);
    // }, 3000);

  }

  getDriversNearestRestaurant(latitude, longitude, radius) {
    this.displayMessage = 'Getting nearest drivers...';
    let self = this;

    if (self.orderSubscribe) {
      self.orderSubscribe.unsubscribe();
    }


    console.log("getDriversNearestRestaurant lat long", latitude + ' ' + longitude);
    console.log('radius', radius);

    const geoQuery = this.driverLocationGeo.query({
      center: [latitude, longitude], //current user location
      radius //in kilometers
    });

    geoQuery.on("key_entered", (key, location, distance) => {
      console.log("key-location-distance", key + ' --- ' + location + ' --- ' + distance);
      this.afDb.database.ref("drivers/" + key).once("value", snap => {
        // console.log("snap", snap);
        this.nearestDriversAround.push({ ...snap.val(), location: location, distance: distance, driverId: key });
      });
    });

    geoQuery.on("ready", (key, location, distance) => {
      console.log("ready key-location-distance", key + ' --- ' + location + ' --- ' + distance);
      console.log(this.nearestDriversAround);
      // unsubscribe
      self.orderSubscribe.unsubscribe();
      setTimeout(() => {
        self.sortByDistnance(self.nearestDriversAround)
      }, 1000);

      // send request to driver
      setTimeout(() => {
        self.sendRequestsToDriver();
      }, 3000);
    });

  }

  sortByDistnance(drivers) {
    drivers.sort(function (a, b) { return (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0); });
  }

  sendRequestsToDriver() {
    this.displayMessage = 'Sending request to drivers...';
    // this.isVisible = false;
    let self = this;

    if (self.nearestDriversAround.length == 0) {
      self.displayMessage = 'Driver are not available at the moment. Please try again later';
    } else {
      console.log("driverIndex", self.driverIndex);
      self.notifyDriver(self.nearestDriversAround[self.driverIndex]);
      self.driverIndex++;
      self.driverSearchTimer = setInterval(function () {
        console.log("driverIndex", self.driverIndex);

        if (self.nearestDriversAround.length < self.driverIndex + 1) {
          clearInterval(self.driverSearchTimer);
          self.displayMessage = 'Driver are not available at the moment. Please try again later';
        } else {
          self.notifyDriver(self.nearestDriversAround[self.driverIndex]);
          self.driverIndex++;
        }
      }, self.timer);
    }

  }

  notifyDriver(driverDetails) {
    console.log("driverDetails", driverDetails);
    this.removPrevRequest();

    if (driverDetails.driverId && driverDetails.user_id && driverDetails.name && driverDetails.email && driverDetails.phone && driverDetails.profile_pic) {
      // let data = {
      //   // customer: {
      //   //   id: this.storage.localData.user_id,
      //   //   name: this.storage.localProfileData.name,
      //   //   email: this.storage.localProfileData.email,
      //   //   phone: this.storage.localProfileData.phone,
      //   //   profile_pic: this.storage.localProfileData.profile_pic
      //   // },
      //   driver: {
      //     id: driverDetails.driverId,
      //     name: driverDetails.name,
      //     email: driverDetails.email,
      //     phone: driverDetails.phone,
      //     profile_pic: driverDetails.profile_pic
      //   }
      // }
      // this.createRequest(driverDetails.driverId, this.storage.localData.user_id, data);

      this.driverDetails = driverDetails;
      this.createRequest(driverDetails.driverId);
    }

  }


  createRequest(driverID) {
    let self = this;
    self.prevDriverIDStore = driverID;
    console.log(driverID);
    // console.log(customerID);
    // console.log(data);
    // self.afDb.object('/requests/' + driverID + '/' + customerID + '/' + self.order_id).update(data);
    self.afDb.object('/driverOrders/' + driverID + '/' + self.fbOrderDetails.uba_orderid).update(self.fbOrderDetails);

    // self.resSubscribe = self.afDb.object('/requests/' + driverID + '/' + customerID + '/' + self.order_id).subscribe(res => {
    self.resSubscribe = self.afDb.object('/driverOrders/' + driverID + '/' + self.fbOrderDetails.uba_orderid).subscribe(res => {
      console.log(res);
      // self.afterAcceptData = res;
      if (res && res.order_status == "2") {
        console.log("accepted");
        self.stopSendingRequest();
        self.requestAcceptedStatus = true;
        self.displayMessage = "Driver accepted the request";
        console.log("driver details", this.driverDetails);
        if (res.uba_driver) {
          // self.getDriverDetails(res.uba_driver);
        }

        /// message display remove
        setTimeout(() => self.isVisible = false, 3000)
      }
      // self.stopSendingRequest()
    });

  }

  stopSendingRequest() {
    let self = this;
    if (self.driverSearchTimer) {
      clearInterval(self.driverSearchTimer);
    }
    if (self.orderSubscribe) {
      self.orderSubscribe.unsubscribe();
    }
  }


  removPrevRequest() {
    let self = this;
    if (this.prevDriverIDStore) {
      // this.afDb.object('/requests/' + this.prevDriverIDStore + '/' + this.storage.localData.user_id + '/' + self.order_id).remove();
      this.afDb.object('/driverOrders/' + this.prevDriverIDStore + '/' + self.fbOrderDetails.uba_orderid).remove();
    }
    if (self.resSubscribe) {
      self.resSubscribe.unsubscribe();
    }
  }

  getDriverDetails(driverId) {
    this.http.get(environment.api + '/driver/' + driverId).subscribe(res => {
      console.log(res);
      this.driverDetails = res;
    })
  }

  getFbDriver(key) {
    this.afDb.database.ref("drivers/" + key).once("value", snap => {
      console.log("snap", snap.val());
      this.driverDetails = snap.val();
      this.isVisible = false;
    });
  }

}
