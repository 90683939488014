import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-thirdparty-order-list',
  templateUrl: './thirdparty-order-list.component.html',
  styleUrls: ['./thirdparty-order-list.component.css']
})
export class ThirdpartyOrderListComponent implements OnInit {
  API = environment.api;
  orderList: any = [];
  drivers: any = [];
  restaurants: any = [];
  reCall: any;
  constructor(private loadingService: LoaderService, public http: HttpClient, public router: Router) { }

  ngOnInit() {
    // this.loadingService.setLoading(true);
    this.getOrderList();
    let self = this;
    this.reCall = setInterval(()=>{
      self.getOrderListReCall();
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.reCall);
  }

  async getOrderList() {
    this.loadingService.setLoading(true);
    this.http.get(this.API + '/thirdPartyOrderList').subscribe((res: any) => {
      this.loadingService.setLoading(false);
      if (res && res.status && res.data) {
        this.orderList = res.data;
        console.log(this.orderList);
        this.restaurants = res.restaurants ? res.restaurants : [];
        this.drivers = res.drivers ? res.drivers : [];
      }
    }, err=>{
      console.log(err);
      this.loadingService.setLoading(false);
    });
  }
  
  async getOrderListReCall() {
    this.http.get(this.API + '/thirdPartyOrderList').subscribe((res: any) => {
      if (res && res.status && res.data) {
        this.orderList = res.data;
        console.log(this.orderList);
        this.restaurants = res.restaurants ? res.restaurants : [];
        this.drivers = res.drivers ? res.drivers : [];
      }
    }, err=>{
      console.log(err);
    });
  }

  statusChange(order){
    console.log(order);
  }

}
