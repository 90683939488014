import { Routes, RouterModule } from '@angular/router';
import { CreateThirdpartyOrderComponent } from './create-thirdparty-order/create-thirdparty-order.component';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { OrderAssignComponent } from './order-assign/order-assign.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderProcessingComponent } from './order-processing/order-processing.component';
import { RegisterComponent } from './register/register.component';
import { ThirdpartyOrderListComponent } from './thirdparty-order-list/thirdparty-order-list.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'order-list', component: OrderListComponent },
    { path: 'order-details', component: OrderDetailsComponent },
    { path: 'order-process', component: OrderProcessingComponent },
    { path: 'order-assign', component: OrderAssignComponent },
    { path: 'create-order-thirdparty', component: CreateThirdpartyOrderComponent },
    { path: 'thirdparty-order-list', component: ThirdpartyOrderListComponent },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);