import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  // localStorage: Storage;
  localStorage = window.localStorage;

  constructor() { }

  ngOnInit() {
    this.localStorage.removeItem('name');
    // this.localStorage.setItem('name','kalyan');
    console.log(this.localStorage.getItem('name'));
    // console.log(window.localStorage);
  }

}
