import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoaderService } from '../loader.service';
declare var $: any;
declare var google: any;

@Component({
  selector: 'app-create-thirdparty-order',
  templateUrl: './create-thirdparty-order.component.html',
  styleUrls: ['./create-thirdparty-order.component.css']
})
export class CreateThirdpartyOrderComponent implements OnInit {
  API = environment.api;
  restaurants: any = [];
  drivers: any = [];
  createForm: FormGroup;
  submitted = false;
  latitude: any;
  longitude: any;
  addressElement: HTMLInputElement = null;
  loadingVar: any;
  @ViewChild('searchbar', { read: ElementRef }) searchbar: ElementRef;

  constructor(private afDb: AngularFireDatabase, private loadingService: LoaderService, public http: HttpClient, public router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      customer_name: ['', [Validators.required]],
      customer_mobile: [''],
      order_amount: ['', [Validators.required]],
      restaurant_id: ['', [Validators.required]],
      driver_id: ['', [Validators.required]],
      customer_address: ['', [Validators.required]]
    });

    this.getResturants().then(()=>{
      this.getDrivers();
    });
    let self = this;
    setTimeout(() => {
      self.initAutocomplete();  
    }, 1000);
    
  }

  get f() { return this.createForm.controls; }

  initAutocomplete(): void {
    // reference : https://github.com/driftyco/ionic/issues/7223
    // this.addressElement = this.searchbar.nativeElement.querySelector('.searchbar-input');
    console.log(this.searchbar);
    this.addressElement = this.searchbar.nativeElement;
    this.createAutocomplete(this.addressElement).subscribe((location) => {
      console.log('Searchdata', location);

      // let options = {
      //   center: location,
      //   zoom: 10
      // };
      // this.map.setOptions(options);
      // this.addMarker(location, "Mein gesuchter Standort");

    });
  }

  createAutocomplete(addressEl: HTMLInputElement): Observable<any> {
    const autocomplete = new google.maps.places.Autocomplete(addressEl);
    // autocomplete.bindTo('bounds', this.map);
    return new Observable((sub: any) => {
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        console.log("place", place);
        if (!place.geometry) {
          // sub.error({
          //   message: 'Autocomplete returned place with no geometry'
          // });
          alert('Not Found! place not found');
        } else {
          console.log('Search Lat', place.geometry.location.lat());
          console.log('Search Lng', place.geometry.location.lng());
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.createForm.patchValue({ customer_address: place.formatted_address ? place.formatted_address : place.name });
              
          sub.next(place.geometry.location);
        }
      });
    });
  }

  submit(){
    let self = this;
    this.submitted = true;
    this.loadingService.setLoading(true);
    if (this.createForm.invalid) {
      this.loadingService.setLoading(false);
      return;
    } else {
      console.log("done", this.createForm);
      // this.loadingService.setLoading(false);
      let formData = new FormData();
      formData.append('customer_name', this.createForm.value.customer_name);
      formData.append('customer_mobile', this.createForm.value.customer_mobile);
      formData.append('order_amount', this.createForm.value.order_amount);
      formData.append('restaurant_id', this.createForm.value.restaurant_id);
      formData.append('driver_id', this.createForm.value.driver_id);
      formData.append('customer_address', this.createForm.value.customer_address);
      formData.append('latitude', this.latitude);
      formData.append('longitude', this.longitude);

      this.http.post(this.API + '/thirdPartyOrderCreate', formData).subscribe((res: any)=>{
        console.log("res", res);
        this.loadingService.setLoading(false);
        if(res && res.status && res.extraData && res.restaurantDetails){
          let obj = {
            type: 'thirdparty',
            driver_id: this.createForm.value.driver_id,
            restaurant_id: this.createForm.value.restaurant_id,
            order_amount: this.createForm.value.order_amount,
            customer_mobile: this.createForm.value.customer_mobile,
            customer_name: this.createForm.value.customer_name,
            date: new Date(),
            uba_orderid: res.data,
            uba_restaurantid: this.createForm.value.restaurant_id,
            restaurant_name: res.restaurantDetails[0].name,
            uba_restulat: res.restaurantDetails[0].res_lat,
            uba_restulong: res.restaurantDetails[0].res_long,
            uba_total: this.createForm.value.order_amount,
            uba_order_status: 1,
            driver_name: res.driverDetails[0].dt_name,
            driver_phone: res.driverDetails[0].dt_phone,
            order_status: 1,
            status_word: 'new',
            uba_driver: this.createForm.value.driver_id,
            uba_date: new Date(),
            uba_address: this.createForm.value.customer_address,
            uba_adsfrom: res.restaurantDetails[0].address,
            uba_lat: res.extraData.customer_lat,
            uba_long: res.extraData.customer_lng,
            uba_username: this.createForm.value.customer_name,
            uba_phone: this.createForm.value.customer_mobile,
            uba_distance: res.mile
          };

          console.log(obj);

          self.afDb.object('/driverOrders/' + self.createForm.value.driver_id + '/' + res.data).update(obj);
          this.router.navigate(['thirdparty-order-list']);
        }else{
          alert(res.message);
        }
      }, err=>{
        console.log(err);
        this.loadingService.setLoading(false);
      });
    }
  }

  async getResturants() {
    this.http.get(this.API + '/restaurantsList').subscribe((res: any) => {
      if (res && res.status && res.data) {
        this.restaurants = res.data;
        console.log(this.restaurants);
        
      }
    });
  }

  async getDrivers() {
    this.http.get(this.API + '/driverList').subscribe((res: any) => {
      if (res && res.status && res.data) {
        this.drivers = res.data;
        console.log(this.drivers);
      }
    });
  }

}
