import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
import { environment } from '../../environments/environment';
declare var $:any;

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  API = environment.api;
  public allOrders: any = [];
  orderSubscribe: any;
  orderDetails: any;
  menuItems: any = [];
  menuSearching: boolean = true;

  constructor(private afDb: AngularFireDatabase, public http: HttpClient, public router: Router) { }

  ngOnInit() {
    this.getAllFbOrders();
    // $('#menu_modal_show_1').modal('open');
  }

  getAllFbOrders() {
    console.log("get");

    // this.afDb.list('/orders', {query: {
    //   orderByKey: false,
    //   // orderByValue: true,
    //   // limitToFirst: 10,
    //  }}).subscribe(res=>{
    //   console.log(res);
    // })
      

    this.afDb.list('/orders').subscribe(res => {
      console.log(res);
      this.allOrders = res.reverse();
    });

    // this.afDb.list('/orders').map( (arr) => { return arr.reverse(); } );
  }

  // getAllOrders(){
  //   this.http.get(this.API + '/orders/' + 1).subscribe(res=>{
  //     console.log(res);
  //     this.allOrders = res;
  //   })
  // }

  getMenu(id) {
    this.emptyMenu();
    this.menuSearching = true;
    this.http.get(this.API + '/order/' + id).subscribe((res: any) => {
      if (res && res.order && res.items) {
        this.orderDetails = res.order;
        this.menuItems = res.items;
        this.menuSearching = false;
      }
    });

    $('#menu_modal_show2').modal('open');
  }

  emptyMenu(){
    this.orderDetails = null;
    this.menuItems = null;
  }

  statusChange(order) {
    let self = this;
    let data = {
      'order_status': order.uba_order_status,
      'uba_order_status': order.uba_order_status
    }
    self.afDb.object('/orders/' + order.uba_orderid).update(data);
  }

  orderAssign(order) {
    this.router.navigate(['order-assign'], {
      queryParams: {
        order_id: order.uba_orderid
      },
    });
  }


}
